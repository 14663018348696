import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogWidgetCard from "../components/blogWidgetCards";
// import Banner from "../components/homedefault/banner";
// import Skill from "../components/homedefault/skill";
// import Service from "../components/homedefault/service";
// import Project from "../components/homedefault/project";
// import Testimonial from "../components/homedefault/testimonial";
// import Brand from "../components/homedefault/brand";
// import Video from "../components/video";

const BlogPage = () => (
  <Layout>
    <SEO title="Dr. Teresa Vasquez | Tech Leader & Educator" />
    <BlogWidgetCard />
  </Layout>
)
export default BlogPage;
