import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
// import Blog from "./blog";
import Post from "./post"
import CategoriesWidget from "./categoriesWidget"

const BlogWidgetCard = () => {
  const blogQueryData = useStaticQuery(graphql`
    query BlogWidgetCardQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            fields {
              slug
            }
            excerpt(pruneLength: 83, format: PLAIN, truncate: true)
            frontmatter {
              author {
                name
              }
              title
              postnumber
              date(formatString: "MMM Do, YYYY")
              format
              category
              image {
              childImageSharp {
                fluid(
                  maxHeight: 300
                  maxWidth: 500
                  quality: 100
                  srcSetBreakpoints: 6
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
            }
          }
        }
      }
    }
  `)
  const blogs = blogQueryData.allMarkdownRemark.edges

  return (
    <div className="rn-post-area rn-section-gap pt-5" id="news">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <div className="breadcrumbs-area">
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="separator">
                    <span></span>
                  </li>
                  <li className="active">Blog</li>
                </ul>
              </div>
              <CategoriesWidget />
            </div>
          </div>
        </div>
        <div className="row">
          {blogs.map(blog => (
            <Post
              column="col-lg-4 col-md-6 col-12"
              key={blog.node.fields.slug}
              content={{
                ...blog.node.fields,
                ...blog.node.frontmatter,
                excerpt: blog.node.excerpt,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlogWidgetCard
